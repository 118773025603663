import React from "react";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import ScreenLoad from "../../../components/screenLoad";
import Main from "../../../components/main";
import Button from "../../../components/button";
import Input from "../../../components/input";
import DeleteTag from "../../../components/deleteTag";
import ContentArea from "../../../components/contentArea";
import TextArea from "../../../components/textArea";
import NotFoundPage from "../../../components/notFoundPage";
import Checkbox from "../../../components/checkbox";
import GenreSelector from "../../../components/genreSelector";
import MediaStickyPreview from "../../../components/mediaStickyPreview";
import HorizontalScroller from "../../../components/horizontalScroller";
import SelectionBox from "../../../components/selectionBox";
import SaveBar from "../../../components/saveBar";
import Consts from "../../../config/consts";
import Common from "../../../config/common";
import Provider from "../../../config/provider";
import Util from "../../../config/util";
import Api from "../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faSpinner, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faInstagram, faFacebookF, faTiktok, faSnapchatGhost, faLinkedinIn, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import bgroovia_tile_icon_500 from "../../../images/bgroovia_tile_icon_500.png";
import bminted_tile_icon_500 from "../../../images/bminted_tile_icon_500.png";

/*

    Minted:
    + Delete - Don't allow
    + Edit - Genres + tags but nothing else. Name + description are not editable.

    For Sale (Not minted):
    + Delete - throw up warning - clear listing - delete media.
    + Edit - Everything can be changed

    None of the above:
    + Delete allowed
    + Edit - everything but the core data

*/
export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class MediaEdit extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                initialising: true,
                saving: false,
                requiresSigning: props.auth.user.requiresSigning === true,
                media: null,
                mediaId: props.params.media_id,

                hasUpdatedScheme: false,
                hasUpdatedName: false,
                hasUpdatedDescription: false,
                hasUpdatedDisplayImage: false,
                hasUpdatedGenres: false,
                hasUpdatedTags: false,

                displayImageKey: null,
                dataDisplayImage: null,
                base64DisplayImage: null,
                uploadedDisplayImageKey: null,

                name: "",
                description: "",
                genres: [],
                type: null,
                collection: null,

                schemes: props.auth.user.profiles.map((p) => p.scheme),
                chosenProfile: null,
                chosenScheme: null,

                newTag: "",
                tags: [],

                isMinted: false,
                isForSale: false,
                hasConfirmedUnlist: false,
                hasConfirmedDelete: false,
                deleting: false,
            };
        }

        componentDidMount() {
            Api.market({
                endpoint: "/content/media",
                method: "GET",
                data: {
                    mediaId: this.props.params.media_id,
                },
            })
                .then((res) => {
                    if (Common.objectPropertyExists(res, "data.created", false) !== true) {
                        navigate(`/m/${this.state.mediaId}`);
                    } else {
                        this.setState({
                            initialising: false,
                            media: res.data,
                            name: res.data.name,
                            description: res.data.description.en,
                            displayImageKey: res.data.displayImage,
                            genres: res.data.genres,
                            collection: res.data.collection,

                            chosenProfile: this.props.auth.user.profiles.filter((p) => p.scheme === res.data.scheme),
                            chosenScheme: res.data.scheme,

                            isMinted: res.data.totalMinted > 0,
                            isForSale: res.data.featuredListing && res.data.featuredListing.state !== "NOT_FOR_SALE" && res.data.featuredListing.state !== "REMOVED_FROM_SALE",
                            tags: res.data.tags,
                            type: res.data.type,
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                    });
                });
        }

        onSelectDisplayImageFile = () => {
            this.selectDisplayImageFile()
                .then((file) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        this.setState({
                            hasUpdatedDisplayImage: true,
                            uploadedDisplayImageKey: null,
                            dataDisplayImage: file,
                            base64DisplayImage: reader.result,
                        });
                    };
                })
                .catch((e) => {
                    Util.notify.error("Invalid file: " + e);
                });
        };
        selectDisplayImageFile = () => {
            return new Promise((resolve, reject) => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = false;
                input.accept = "image/*";
                input.onchange = (_) => {
                    let files = Array.from(input.files);
                    if (files[0].size > 50000000) {
                        reject("File size too big.");
                    } else {
                        resolve(files[0]);
                    }
                };
                input.click();
            });
        };

        uploadDisplayImage = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.dataDisplayImage) {
                    reject();
                } else if (this.state.uploadedDisplayImageKey) {
                    resolve(this.state.uploadedDisplayImageKey);
                } else {
                    //let contentType = this.state.base64DisplayImage.substring(5, this.state.base64DisplayImage.indexOf(";")).toLowerCase();
                    Api.market({
                        endpoint: "/content/upload/url/",
                        method: "GET",
                        data: {
                            contentType: this.state.dataDisplayImage.type.toLowerCase(),
                            dataType: "MEDIA_DISPLAY_IMAGE",
                        },
                    })
                        .then((upload_res) => {
                            axios({
                                method: "PUT",
                                url: upload_res.url,
                                headers: {
                                    "Content-Type": this.state.dataDisplayImage.type.toLowerCase(),
                                },
                                data: this.state.dataDisplayImage,
                            })
                                .then(() => {
                                    this.setState(
                                        {
                                            uploadedDisplayImageKey: upload_res.key,
                                        },
                                        () => {
                                            this.checkUploadStatus(upload_res.key, 20)
                                                .then(() => {
                                                    resolve(upload_res.key);
                                                })
                                                .catch((e) => {
                                                    reject();
                                                });
                                        }
                                    );
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }
            });
        };

        checkUploadStatus = (key, maxRetries, retryCount = 0) => {
            return new Promise((resolve, reject) => {
                Api.market({
                    endpoint: "/content/upload/status/",
                    method: "GET",
                    data: {
                        key,
                    },
                })
                    .then((status_res) => {
                        if (status_res.status !== "UPLOADED") {
                            if (maxRetries > retryCount) {
                                setTimeout(() => {
                                    this.checkUploadStatus(key, maxRetries, retryCount + 1)
                                        .then(resolve)
                                        .catch(reject);
                                }, 1000);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(reject);
            });
        };

        onUnlockAccount = () => {
            Provider.SignMessageWithAccountWallet(this.props.auth.user.addresses)
                .then((signature) => {
                    Api.market({
                        endpoint: "/auth/message",
                        method: "POST",
                        data: {
                            signature: signature,
                        },
                    })
                        .then(() => {
                            // Successfully verified the signature - a refresh
                            // should sort out any currently hidden data
                            window.location.reload();
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                })
                .catch((e) => {
                    console.error(e);
                });
        };

        selectProfile = (profile) => {
            this.setState({
                chosenProfile: profile,
                chosenScheme: profile.scheme,
                hasUpdatedScheme: true,
            });
        };

        onNameUpdated = (text) => {
            this.setState({
                name: text,
                hasUpdatedName: true,
            });
        };
        onDescriptionUpdated = (text) => {
            this.setState({
                description: text,
                hasUpdatedDescription: true,
            });
        };

        onSave = () => {
            let request_data = {};
            let updated_display_image = this.state.type !== "IMAGE" && this.state.hasUpdatedDisplayImage;

            // Run through any updated details and create an object worth of
            // data we want to update
            if (this.state.hasUpdatedScheme) {
                request_data.scheme = this.state.chosenScheme;
            }
            if (this.state.hasUpdatedName) {
                request_data.name = this.state.name;
            }
            if (this.state.hasUpdatedDescription) {
                request_data.description = this.state.description;
            }
            if (this.state.hasUpdatedGenres) {
                request_data.genres = this.state.genres;
            }
            if (this.state.hasUpdatedTags) {
                request_data.tags = this.state.tags;
            }

            // Check we have something to update
            if (!updated_display_image && Object.keys(request_data).length <= 0) {
                // Nothing to update - just redirect
                navigate(`/m/${this.state.mediaId}`);
            } else {
                // Set the save flag
                this.setState(
                    {
                        saving: true,
                    },
                    () => {
                        // If we've update our image we'll need to update that file first
                        if (updated_display_image) {
                            this.uploadDisplayImage()
                                .then((image_key) => {
                                    Api.market({
                                        endpoint: "/content/media",
                                        method: "PATCH",
                                        data: {
                                            mediaId: this.state.mediaId,
                                            displayImageKey: image_key,
                                            ...request_data,
                                        },
                                    })
                                        .then(() => {
                                            // Successfully saved
                                            navigate(`/m/${this.state.mediaId}`);
                                        })
                                        .catch((e) => {
                                            this.setState(
                                                {
                                                    saving: false,
                                                },
                                                () => {
                                                    Util.notify.error(`Failed to update your media. Please check the data and retry or try again later.`);
                                                }
                                            );
                                        });
                                })
                                .catch((e) => {
                                    if (e && e.response && e.response.status === 403) {
                                        console.error(e.response.data);
                                        Util.notify.error(
                                            `It looks like you don't have permission to create media at this time! Please check your account verification is complete before continuing.`
                                        );
                                    } else if (e && e.response) {
                                        console.error(e.response.data); // => the response payload
                                    }
                                    Util.notify.error(`Failed to upload the media data. Please retry or try again later.`);
                                    this.setState({
                                        saving: false,
                                    });
                                });
                        } else {
                            Api.market({
                                endpoint: "/content/media",
                                method: "PATCH",
                                data: {
                                    mediaId: this.state.mediaId,
                                    ...request_data,
                                },
                            })
                                .then(() => {
                                    // Successfully saved
                                    navigate(`/m/${this.state.mediaId}`);
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            saving: false,
                                        },
                                        () => {
                                            Util.notify.error(`Failed to update your media. Please check the data and retry or try again later.`);
                                        }
                                    );
                                });
                        }
                    }
                );
            }
        };

        refreshPage = () => {
            window.location.reload();
        };

        onAddTagTextChanged = (text) => {
            this.setState(
                {
                    newTag: text,
                },
                () => {
                    if (/[^0-9a-zA-Z]/.test(this.state.newTag)) {
                        this.onAddTags(this.state.newTag);
                    }
                }
            );
        };
        onAddTags = (tag) => {
            let newTags = tag.replace(/[^0-9a-zA-Z]/g, "_").split("_");
            let addTags = [];
            for (let i = 0; i < newTags.length; i++) {
                try {
                    let t = newTags[i].trim().toLowerCase();
                    if (t.length > 2 && this.state.tags.indexOf(t) === -1) {
                        addTags.push(t);
                    }
                } catch (e) {}
            }
            this.setState({
                hasUpdatedTags: true,
                tags: [...this.state.tags, ...addTags],
                newTag: "",
            });
        };

        onRemoveTag = (tag) => {
            if (this.state.tags.indexOf(tag) > -1) {
                this.setState({
                    hasUpdatedTags: true,
                    tags: this.state.tags.filter((t) => {
                        return t !== tag;
                    }),
                });
            }
        };

        onGenresChanged = (genres) => {
            this.setState({
                hasUpdatedGenres: true,
                genres,
            });
        };

        onConfirmUnlist = (id, val) => {
            this.setState({
                hasConfirmedUnlist: val,
            });
        };

        onConfirmDelete = (id, val) => {
            this.setState({
                hasConfirmedDelete: val,
            });
        };

        onDeleteMedia = () => {
            if (this.state.isMinted) {
                Util.notify.error(`Minted media cannot be deleted from the platform right now.`);
            } else if (this.state.isForSale && !this.state.hasConfirmedUnlist) {
                Util.notify.error(`Please confirm you are happy to unlist this media by tapping the Unlist Confirmation checkbox.`);
            } else if (!this.state.hasConfirmedDelete) {
                Util.notify.error(`Please confirm you want to delete this media by tapping the Delete Confirmation checkbox.`);
            } else {
                this.setState(
                    {
                        deleting: true,
                    },
                    () => {
                        Provider.SignMessageWithAccountWallet(this.props.auth.user.addresses)
                            .then((signature) => {
                                Api.market({
                                    endpoint: "/content/media",
                                    method: "DELETE",
                                    data: {
                                        mediaId: this.state.mediaId,
                                        signature: signature,
                                    },
                                })
                                    .then(() => {
                                        const bminted_profile = this.props.auth.user.profiles.filter((p) => p.scheme === "BMINTED");
                                        if (bminted_profile.length > 0 && bminted_profile[0].displayName) {
                                            navigate(`/u/${bminted_profile[0].displayName}`);
                                        } else {
                                            navigate("/");
                                        }
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        this.setState(
                                            {
                                                deleting: false,
                                            },
                                            () => {
                                                Util.notify.error(`Failed to delete your media. Please retry or try again later.`);
                                            }
                                        );
                                    });
                            })
                            .catch((e) => {
                                console.error(e);
                                this.setState(
                                    {
                                        deleting: false,
                                    },
                                    () => {
                                        Util.notify.error(`Failed to delete your media. Please retry or try again later.`);
                                    }
                                );
                            });
                    }
                );
            }
        };

        render() {
            return !this.state.media ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Media"} />
                </Main>
            ) : (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea extraTopPadding={true} centered={true} slim={true}>
                        {this.state.type !== "IMAGE" && (
                            <ContentArea header={"Display Image."} bottomRule={false}>
                                <TextArea>
                                    <p>This image will be used to publicly identify this media. Please note that it can take a few minutes for any new image to propogate through the market.</p>
                                    <div className={styles.display_image_container}>
                                        <div className={styles.display_image_left}>
                                            <div className={styles.display_image}>
                                                {!!this.state.base64DisplayImage ? (
                                                    <img src={this.state.base64DisplayImage} />
                                                ) : (
                                                    this.state.displayImageKey && <img src={`${process.env.GATSBY_STORAGE}media/${this.state.displayImageKey}`} />
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.display_image_right}>
                                            <TextArea>
                                                <p>This image should be 500x500 in resolution and can be of filetype: .png, .gif or .jpg</p>
                                                <br />
                                                <Button displayMode={1} hoverMode={6} text={"Change Image"} style={{ margin: 0 }} onClick={this.onSelectDisplayImageFile} />
                                            </TextArea>
                                        </div>
                                    </div>
                                </TextArea>
                                <br />
                            </ContentArea>
                        )}

                        {this.state.schemes.length > 1 && this.state.collection === null && (
                            <ContentArea bottomRule={true} header={"Profile."}>
                                <TextArea header={"Associate."}>
                                    <p>Which of your profiles would you like this media to be associated with?</p>
                                    <HorizontalScroller>
                                        {this.props.auth.user.profiles.map((p, index) =>
                                            p.scheme === "BGROOVIA" ? (
                                                <SelectionBox
                                                    key={`profile_${index}`}
                                                    text={p.displayName}
                                                    subtext={"B Groovia"}
                                                    image={bgroovia_tile_icon_500}
                                                    backgroundImage={p.profileImage ? `${process.env.GATSBY_STORAGE}user/${p.profileImage}` : null}
                                                    selected={this.state.chosenScheme === p.scheme}
                                                    onSelected={() => {
                                                        this.selectProfile(p);
                                                    }}
                                                />
                                            ) : p.scheme === "BMINTED" ? (
                                                <SelectionBox
                                                    key={`profile_${index}`}
                                                    text={p.displayName}
                                                    subtext={"B Minted"}
                                                    image={bminted_tile_icon_500}
                                                    backgroundImage={p.profileImage ? `${process.env.GATSBY_STORAGE}user/${p.profileImage}` : null}
                                                    selected={this.state.chosenScheme === p.scheme}
                                                    onSelected={() => {
                                                        this.selectProfile(p);
                                                    }}
                                                />
                                            ) : null
                                        )}
                                    </HorizontalScroller>
                                </TextArea>
                                <br />
                            </ContentArea>
                        )}

                        <ContentArea header={"Media Details."} bottomRule={true}>
                            <TextArea header={"Name."}>
                                <p>Your Media Name is displayed publicly to identify the media.</p>
                                <Input placeholder={"Name your media"} onTextChanged={this.onNameUpdated} defaultValue={this.state.name} maxLength={50} />
                            </TextArea>
                            <br />
                            <TextArea header={"Description."}>
                                <p>The description is publicly displayed on the medias detail page. Line breaks are allowed but multiple will be cropped to 1.</p>
                                <Input multiline={true} placeholder={"Describe your media"} onTextChanged={this.onDescriptionUpdated} defaultValue={this.state.description} maxLength={400} />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Search Optimization."} bottomRule={true}>
                            <TextArea header={"Genres."}>
                                <p>
                                    We want to make sure that we categorise your media as you'd intended, please select <b>at least 1</b> of the genres below:
                                </p>
                                <GenreSelector selectedGenres={this.state.genres} selectedGenresChanged={this.onGenresChanged} />
                            </TextArea>
                            <br />
                            <TextArea header={"Tags."}>
                                <p>
                                    Tags are a collection of individual words that describe your media and make it easier to find through searching. A tag can only contain A to Z or 0 to 9 characters
                                    and be no smaller than 2 characters in length.
                                </p>
                                <div className={styles.tag_container}>
                                    {this.state.tags.length <= 0 ? (
                                        <p>
                                            <i>No Tags Added Yet. Enter a word and hit the 'Enter' key to submit, or type a 'space' character after your tag to submit.</i>
                                        </p>
                                    ) : (
                                        this.state.tags.map((tag, index) => (
                                            <DeleteTag
                                                key={`delete_tag_${index}`}
                                                deleting={false}
                                                onDelete={() => {
                                                    this.onRemoveTag(tag);
                                                }}
                                                text={tag}
                                            />
                                        ))
                                    )}
                                </div>
                                <Input
                                    disabled={this.state.tags.length >= 10}
                                    placeholder={"Enter a new tag here..."}
                                    onTextChanged={this.onAddTagTextChanged}
                                    onSubmit={this.onAddTags}
                                    defaultValue={this.state.newTag}
                                    maxLength={20}
                                />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Delete."} bottomRule={false}>
                            {this.state.isMinted ? (
                                <TextArea>
                                    <p>
                                        Deleting media is restricted to only media that is <b>not minted</b> and <b>not currently up for sale</b>. As your media has been minted we can no longer delete
                                        this media.
                                    </p>
                                </TextArea>
                            ) : this.state.isForSale ? (
                                <TextArea>
                                    <p>
                                        Deleting media is restricted to only media that is <b>not minted</b> and <b>not currently up for sale</b>. As your media is listed for sale we would need to
                                        unlist and then delete. Please note that this process is permenant and <u>cannot be undone</u>. Tap both checkboxs below to confirm your understand and to allow
                                        you to unlist and delete.
                                    </p>
                                    <Checkbox checked={this.state.hasConfirmedUnlist} onCheckChanged={this.onConfirmUnlist} text={"Please unlist my media from sale."} />
                                    <Checkbox checked={this.state.hasConfirmedDelete} onCheckChanged={this.onConfirmDelete} text={"Please delete this media."} />
                                    <Button
                                        displayMode={5}
                                        hoverMode={6}
                                        disabled={!this.state.hasConfirmedDelete || !this.state.hasConfirmedUnlist}
                                        text={this.state.deleting ? "Deleting..." : "Delete this Media"}
                                        onClick={this.onDeleteMedia}
                                        style={{ margin: 0, marginTop: 15 }}
                                    />
                                </TextArea>
                            ) : (
                                <TextArea>
                                    <p>
                                        Deleting media is restricted to only media that is <b>not minted</b> and <b>not currently up for sale</b>. Please note that this will be permenant and{" "}
                                        <u>cannot be undone</u>. Tap the checkbox below to confirm you understand and to allow you to delete.
                                    </p>
                                    <Checkbox checked={this.state.hasConfirmedDelete} onCheckChanged={this.onConfirmDelete} text={"I understand and request you delete this media."} />
                                    <Button
                                        displayMode={5}
                                        hoverMode={6}
                                        disabled={!this.state.hasConfirmedDelete}
                                        text={this.state.deleting ? "Deleting..." : "Delete this Media"}
                                        onClick={this.onDeleteMedia}
                                        style={{ margin: 0, marginTop: 15 }}
                                    />
                                </TextArea>
                            )}
                            <br />
                        </ContentArea>

                        <br />
                        <br />
                    </ContentArea>

                    <SaveBar
                        disabled={
                            this.state.saving ||
                            (!this.state.hasUpdatedScheme &&
                                !this.state.hasUpdatedName &&
                                !this.state.hasUpdatedDescription &&
                                !this.state.hasUpdatedDisplayImage &&
                                !this.state.hasUpdatedGenres &&
                                !this.state.hasUpdatedTags)
                        }
                        text={this.state.saving ? "Saving..." : "Save Changes"}
                        onSave={this.onSave}
                    />

                    <MediaStickyPreview
                        media={{
                            ...this.state.media,
                            name: this.state.name,
                            scheme: this.state.chosenScheme,
                            creator: {
                                ...this.state.media.creator,
                                type: this.state.chosenScheme,
                            },
                        }}
                        mediaId={this.state.mediaId}
                        image={`${process.env.GATSBY_STORAGE}media/${this.state.media.displayImage}`}
                        text={this.state.media.name}
                    />
                </Main>
            );
        }
    }
);
